<template>
  <section class="box-height" style="margin-bottom: 70px">
    <!-- 标题 -->
    <div class="box-title" style="border: 1px #e5e5e5 solid">
      <!-- 表单 -->
      <el-row :gutter="24">
        <el-col>
          <div style="display: flex">
            <el-button
              size="small"
              type="primary"
              @click="addNew"
              style="margin-left: 10px; height: 32px"
            >
              <i class="el-icon-plus"></i> 新增签约公司
            </el-button>
            <el-row>
              <el-form
                @submit.native.prevent
                :model="filter"
                ref="filter"
                style="padding-left: 10px"
              >
                <el-row :gutter="24">
                  <el-col :span="10">
                    <el-form-item
                      label=""
                      prop="name"
                      style="margin-bottom: 10px"
                    >
                      <el-input
                        v-model="filter.name"
                        placeholder="请输入签约公司名称"
                        style="width: 240px"
                      >
                        <el-button
                          slot="append"
                          icon="el-icon-search"
                          @click="submitForm('filter')"
                        ></el-button>
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-row>
          </div>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
        <el-col :span="24">
          <el-table
            :data="ListData"
            class="tabBorder custor"
            :header-row-style="{ height: '36px', margin: '0' }"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            v-loading="listLoading"
          >
            <el-table-column
              :show-overflow-tooltip="true"
              prop="signCompanyName"
              label="签约公司名称"
              align="left"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="是否启用"
              align="left"
              width="150"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status">是</span>
                <span v-else>否</span>
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creatorUserName"
              label="创建人"
              align="left"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop="creationTime"
              label="创建时间"
              align="left"
              width="200"
            ></el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              prop
              label="操作"
              align="left"
              width="150"
            >
              <template slot-scope="scope">
                <span @click="handleEdit(scope.row.id)" class="tabHref"
                  >编辑</span
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :hide-on-single-page="pagination.total > 10 ? false : true"
            v-if="pagination.total > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pagination.pageSize"
            :pager-count="5"
            layout="total,prev, pager, next,sizes"
            :total="pagination.total"
          ></el-pagination>
        </el-col>
      </el-row>
    </div>

    <!-- 弹框 -->
    <!-- modal-append-to-body -->
    <!-- 遮罩层是否插入至 body 元素上，若为 false，则遮罩层会插入至 Dialog 的父元素上 -->
    <!-- close-on-click-modal -->
    <!-- 是否可以通过点击 modal 关闭 Dialog -->
    <el-dialog
      :title="titName"
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        @submit.native.prevent
        :model="createDate"
        ref="createDate"
        :rules="rules"
        label-width="120px"
      >
        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="签约公司名称：" prop="signCompanyName">
              <el-input
                v-model.trim="createDate.signCompanyName"
                style="width: 320px"
                placeholder="请填写签约公司名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="是否启用：" prop="status">
              <el-radio-group v-model="createDate.status">
                <el-radio label="true">是</el-radio>
                <el-radio label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="resetForm('createDate')"
          >取 消</el-button
        >
        <el-button
          size="mini"
          type="primary"
          @click="save('createDate')"
          :loading="saveloading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </section>
</template>
<script>
import {
  getSigncompanyList,
  addOrEditSigncompany,
  getSigncompanyById,
} from "../../api/api";
export default {
  created() {
    this.loadList();
  },
  destroyed: function () {
    //this.disconnect();
  },
  data() {
    return {
      ListData: [],
      pagination: {
        total: 0,
        pageNo: 1,
        pageSize: 50,
      },
      saveloading: false,
      listLoading: false,
      dialogVisible: false,
      rules: {
        signCompanyName: [
          {
            required: true,
            trigger: "blur",
            message: "请填写签约公司名称",
          },
        ],
        status: [
          {
            required: true,
            trigger: "blur",
            message: "请选择是否启用",
          },
        ],
      },
      createDate: {
        id: 0,
        signCompanyName: null,
        status: null,
        version: 0,
      },
      titName: "",
      filter: {
        name: "",
      },
    };
  },
  methods: {
    submitForm() {
      this.pagination.pageNo = 1;
      this.loadList();
    },
    loadList() {
      window.scrollTo(0, 0);
      this.listLoading = true;
      getSigncompanyList({
        current: this.pagination.pageNo,
        size: this.pagination.pageSize,
        signCompanyName: this.filter.name,
        searchCount: true,
      }).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.ListData = res.result.records;
          this.pagination.total = res.result.total;
        }
        this.listLoading = false;
      });
    },

    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.pagination.pageSize = val;
      this.pagination.pageNo = 1;
      this.loadList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.pagination.pageNo = val;
      this.loadList();
    },
    handleClose(done) {
      done();
      this.resetForm("createDate");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.filter.name = "";
      this.dialogVisible = false;
    },
    handleEdit: function (id) {
      this.dialogVisible = true;
      this.titName = "编辑签约公司";
      getSigncompanyById({
        id: id,
      }).then((res) => {
        if (res.success) {
          this.createDate.signCompanyName = res.result.signCompanyName;
          this.createDate.status = res.result.status.toString();
          this.createDate.id = id;
          this.createDate.version = res.result.version;
        }
      });
    },
    addNew: function () {
      this.createDate.id = 0;
      this.createDate.version = 0;
      this.dialogVisible = true;
      this.titName = "新增签约公司";
    },

    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveloading = true;
          addOrEditSigncompany({
            id: this.createDate.id,
            signCompanyName: this.createDate.signCompanyName,
            status: this.createDate.status,
            version: this.createDate.version,
          }).then((res) => {
            if (res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.resetForm("createDate");
              this.loadList();
            }
            this.saveloading = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-title {
  padding: 15px 0;
  .tip {
    margin: 0 0 0 15px;
  }
  .tabBorder {
    margin: 0 0 15px 0;
  }
}
</style>